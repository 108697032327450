import React from 'react';
//gatsby
import { graphql } from 'gatsby';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
//components
import Layout from 'components/ui/layout';
//parser
import parse from "html-react-parser";

//styled
const PageWrap = styled.section`
  position: relative;
`;

const PageContent = styled.div`
  position: relative;
`;

const Page = ({ data }) => {

  const page = data.page;

  // const replaceImg = {
  //   replace: domNode => {
  //     if (domNode.attribs && domNode.name === 'img') {
  //       const props = attributesToProps(domNode.attribs);
  //       console.log(props);
  //       return <img src={props['data-src']} srcSet={props['data-srcset']} />;
  //     }
  //   }
  // };

  return (
    <Layout
      headerBg={page.headerBg}
      headerColor={page.headerColor}
      >
      <Seo post={page} />
      <PageWrap>
        <PageContent>
          {!!page.content && (
            parse(page.content)
          )}
        </PageContent>
      </PageWrap>
    </Layout>
  );
};

export const pageQuery = graphql`
query PageById( $id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      headerColor
      headerBg
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`

export default Page;